.pref
{
  padding: 8px 0;

  h1.iemeshi
  {
    line-height: 30px;
    font-size: 24px;
    text-align: right;

    img
    {
      width: 30px;
      height: 30px;
      vertical-align: middle;
      margin-right: 4px;
    }
  }

  h2
  {
    font-size: 1.6rem;
  }

  .nav
  {
    margin-top: 24px;
  }

  .go-back
  {
    margin-top: 24px;
    text-align: right;
  }

  .nav
  {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin: 24px -4px 0 -4px;

    .pref
    {
      width: 25%;
      padding: 4px;
      box-sizing: border-box;

      .link
      {
        display: block;
        background-color: #f5f5f5;
        width: 100%;
        text-align: center;
        padding: 4px;
        box-sizing: border-box;
        background-color: #007bff;
        color: #ffffff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
}
