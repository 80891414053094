.geolonia-svg-map
{
  width: 100%;
  height: auto;
}

.iemeshi
{
  font-family: "Nico Moji";
}
